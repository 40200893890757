/** @jsxImportSource @emotion/react */
import {useEffect} from "react";
import {
  Descriptions,
  Form,
  Input,
  Row,
  Col,
  Radio,
  Button,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import {useNavigate, useLocation} from "react-router-dom";
import {observer} from "mobx-react";

import OnlyNumberInput from "@components/input/OnlyNumberInput";
import DetailContainer from "@components/container/DetailContainer";
import DatePicker from "@components/picker/DatePicker";
import AgentSelect from "@app/components/select/AgentSelect";

import {useMobxStores} from "@stores/stores";
import {getQuery, pushQuery} from "@utils/router";
import {isEmail} from "@utils/validation";
import userApi from "@api/user";

const initialValues = {
  name: null,
  phoneNumber: null,
  enabled: true,
  locked: false,
};

export default observer(function UserStatusDetail(props) {
  const {id, fetchList} = props;
  const [form] = Form.useForm();
  const {globalStore} = useMobxStores();
  const navigate = useNavigate();
  const location = useLocation();
  const query = getQuery(location);

  function close() {
    delete query.id;
    delete query.add;
    pushQuery(query, navigate);
  }

  async function fetchUser() {
    // 사용자 조회
    try {
      const userProm = await userApi.getAdminById(id);
      const userRes = userProm?.data?.data;
      if (userRes.expireDate) {
        userRes.expireDateDay = dayjs(userRes.expireDate);
        userRes.expireDateTime = dayjs(userRes.expireDate);
      }
      form.setFieldsValue(userRes);
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      );
    }
  }

  async function update() {
    // 사용자 정보 수정
    try {
      let data = form.getFieldValue();
      if (data?.name === null || data?.name === "")
        return globalStore.warningNoti("이름을 입력해주세요.");
      if (data?.phoneNumber === null || data?.phoneNumber === "")
        return globalStore.warningNoti("전화번호를 입력해주세요.");
      if (data?.email === null || data?.email === "" || !isEmail(data?.email))
        return globalStore.warningNoti("이메일을 확인해주세요.");
      if (data?.enabled === null)
        return globalStore.warningNoti("계정 상태를 선택해주세요.");
      if (data?.locked === null)
        return globalStore.warningNoti("잠김 상태를 선택해주세요.");
      if (data.expireDateDay) {
        data.expireDate =
          data.expireDateDay.format("YYYY-MM-DD") +
          (data.expireDateTime
            ? dayjs(data.expireDateTime).format(" HH:mm:ss")
            : " 00:00:00");
      }
      if (data.agent?.id) {
        data.agent = {
          id: data.agent.id,
        };
      } else {
        delete data.agent;
      }
      delete data.expireDateDay;
      delete data.expireDateTime;
      if (id) {
        await userApi.putAdmin(id, data);
      } else {
        if (data?.password === null || data?.password === "") {
          return globalStore.warningNoti("비밀번호를 입력해주세요.");
        }
        await userApi.createAdmin(data);
        close();
      }
      globalStore.successNoti(id ? "수정되었습니다." : "등록되었습니다.");
      fetchList();
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      );
    }
  }

  useEffect(() => {
    if (id) {
      fetchUser();
    }
  }, [id]);

  return (
    <>
      <DetailContainer
        viewSiderProps={{
          title: id ? "사용자 수정" : "사용자 등록",
          onClose: () => {},
        }}
      >
        <div css={styles.container}>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Row gutter={[16, {xs: 16, sm: 24}]}>
              <Col xs={24} sm={12}>
                <Form.Item name="id" label="아이디" required>
                  <Input disabled={id} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="name" label="이름" required>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="phoneNumber" label="전화번호" required>
                  <OnlyNumberInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="email" label="이메일" required>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={["agent", "id"]} label="사업장" required={!id}>
                  <AgentSelect allowClear />
                </Form.Item>
              </Col>
              {!id && (
                <Col xs={24} sm={12}>
                  <Form.Item name="password" label="비밀번호" required>
                    <Input.Password />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24}>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="계정 상태">
                        <Form.Item name="enabled">
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={[
                              {label: "활성", value: true},
                              {label: "비활성", value: false},
                            ]}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="잠김 상태">
                        <Form.Item name="locked">
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={[
                              {label: "잠김", value: true},
                              {label: "미잠김", value: false},
                            ]}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  {id && (
                    <Col xs={24}>
                      <Descriptions>
                        <Descriptions.Item label="계정 만료 날짜">
                          <Form.Item name="expireDateDay">
                            <DatePicker allowClear={false} />
                          </Form.Item>
                          <Form.Item name="expireDateTime">
                            <DatePicker.TimePicker />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={24} align="right">
                <Popconfirm
                  title={id ? `수정하시겠습니까?` : "등록하시겠습니까?"}
                  onConfirm={update}
                  placement="topLeft"
                >
                  <Button type="primary">{id ? "수정" : "등록"}</Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form>
        </div>
      </DetailContainer>
    </>
  );
});

const styles = {
  container: {
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 90,
        marginRight: 7,
      },
    },
  },
};
