import {useState, useEffect} from "react";
import {Select} from "antd";
import {observer} from "mobx-react";
import _, {debounce} from "lodash";

import agentApi from "@api/agent";

export default observer(function AgentSelect(props) {
  const {allSearch, partSearch, value, style, allowClear, params} = props;
  const [agents, setAgents] = useState([]);
  const [selectValue, setSelectValue] = useState();
  const [textFilter, setTextFilter] = useState("");

  async function getAgents() {
    try {
      const agentProm = await agentApi.getAgents(params);
      const agentRes = agentProm?.data?.data?.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
      if (partSearch) {
        agentRes?.unshift({
          label: "사업자별",
          value: "byAgent",
        });
      }
      if (allSearch) {
        agentRes?.unshift({
          label: "전체",
          value: "all",
        });
      }
      // onChange(value || (allSearch && "all") || null)
      setAgents(agentRes);
    } catch (e) {
      console.log(e?.response?.data?.meta?.userMessage || e);
    }
  }

  function onChange(value) {
    setSelectValue(value);
    const {onChange} = props;
    if (onChange) onChange(value);
  }

  const onTextDebounce = debounce((text) => {
    setTextFilter(text);
  }, 300);

  useEffect(() => {
    getAgents();
  }, []);

  useEffect(() => {
    if (value) setSelectValue(value);
  }, [value]);

  return (
    <Select
      style={style}
      showSearch={(e) => console.log(e)}
      dropdownMatchSelectWidth={true}
      filterOption={(input, option) => {
        if (textFilter?.length < 2) return true;
        else
          return (
            option.label?.toLowerCase().indexOf(textFilter.toLowerCase()) >= 0
          );
      }}
      value={selectValue}
      options={agents}
      onChange={onChange}
      allowClear={allowClear}
      onSearch={(e) => onTextDebounce(e)}
    />
  );
});
