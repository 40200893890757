import {Form} from "antd";

import SearchForm from "@components/form/SearchForm";
import AgentSelect from "@app/components/select/AgentSelect";

import {useMobxStores} from "@stores/stores";

const initialValues = {
  agentId: null,
};

export default function StatusSearch(props) {
  const {onSearch} = props;
  const {globalStore} = useMobxStores();
  const [form] = Form.useForm();

  return (
    <>
      <SearchForm
        onFinish={() => {
          const agent = form?.getFieldValue()?.agentId;
          if (!agent) {
            return globalStore.warningNoti("사업장을 선택해주세요.");
          } else {
            onSearch(form?.getFieldValue()?.agentId);
          }
        }}
        footerButtons={true}
      >
        <Form form={form} initialValues={initialValues}>
          <Form.Item name="agentId" label="사업장">
            <AgentSelect
              style={{width: 180}}
              value={form?.getFieldValue()?.agentId}
              allowClear
            />
          </Form.Item>
        </Form>
      </SearchForm>
    </>
  );
}
