import {useEffect} from "react";
import {Form, Input, Radio, Select, Space, Row, Col} from "antd";

import AddButton from "@components/button/AddButton";
import SearchForm from "@components/form/SearchForm";
import DatePicker from "@components/picker/DatePicker";
import AgentSelect from "@app/components/select/AgentSelect";

const initValue = {
  fromDate: null,
  toDate: null,
  agentId: "all",
  existAgent: "all",
  enabled: "all",
  expired: "all",
  keywordType: "name",
  keyword: null,
  sort: "registrationDate,desc",
};

export default function UserStatusSearch(props) {
  const {search, openViewSider} = props;
  const [form] = Form.useForm();

  function onSearch() {
    const params = form.getFieldValue();
    search(params);
  }

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <>
      <SearchForm
        onFinish={onSearch}
        footerButtons={
          <AddButton
            onClick={() => openViewSider("add", null)}
            text="사용자 등록"
          />
        }
      >
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                });
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
            />
          </Form.Item>
          <Form.Item name="existAgent" label="사업장 할당">
            <Radio.Group
              options={[
                {label: "전체", value: "all"},
                {label: "할당", value: true},
                {label: "미할당", value: false},
              ]}
            ></Radio.Group>
          </Form.Item>
          <Form.Item name="enabled" label="계정 상태">
            <Radio.Group
              options={[
                {label: "전체", value: "all"},
                {label: "활성", value: true},
                {label: "비활성", value: false},
              ]}
            ></Radio.Group>
          </Form.Item>
          <Form.Item name="expired" label="계정 만료">
            <Radio.Group
              options={[
                {label: "전체", value: "all"},
                {label: "만료", value: true},
                {label: "사용", value: false},
              ]}
            ></Radio.Group>
          </Form.Item>
          <Row>
            <Col sm={24} md={24} lg={24} xl={8}>
              <Form.Item name="agentId" label="사업장">
                <AgentSelect allSearch allowClear style={{width: 180}} />
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={24} xl={16}>
              <Space>
                <Form.Item name="keywordType">
                  <Select
                    options={[
                      {label: "이름", value: "name"},
                      {label: "아이디", value: "id"},
                      {label: "이메일", value: "email"},
                    ]}
                  />
                </Form.Item>
                <Form.Item name="keyword">
                  <Input />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </SearchForm>
    </>
  );
}
