import agent from "@app/axios/agent";
import blackWhite from "@app/axios/blackWhite";
import manage from "@app/axios/manage";
import DetailContainer from "@app/components/container/DetailContainer";
import AgentSelect from "@app/components/select/AgentSelect";
import BlackNumberInputModal from "@app/containers/management/blackWhite/detail/BlackNumberInputModal";
import BlackWhiteDetailAllTable from "@app/containers/management/blackWhite/detail/BlackWhiteDetailAllTable";
import BlackWhiteDetailNumberTable from "@app/containers/management/blackWhite/detail/BlackWhiteDetailNumberTable";
import {useMobxStores} from "@app/stores/stores";
import {objectClean} from "@app/utils/router";
import {
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import {useState} from "react";
import {useEffect} from "react";

export default function BlackWhiteListDetail(props) {
  const {globalStore, userStore} = useMobxStores();
  const {id, closeDetail, listType, status, fetchList} = props;
  const [form] = Form.useForm();
  //종류
  const switchOptions = [
    {label: "사용", value: "on"},
    {label: "미사용", value: "off"},
  ];
  const [allList, setAllList] = useState([]); //전체 설정 블랙,화이트 리스트
  const [listByNum, setListByNum] = useState([]);
  const [viewSiderProps, setViewSiderProps] = useState({
    title: "블랙/화이트 리스트 관리",
    okTitle: id ? "수정" : "등록",
    bottomActionBar: true,
    onOk: () => {
      form.submit();
    },
    onClose: () => closeDetail(),
  });
  const [inputModal, setInputModal] = useState(null); //전체 발신 번호 등록 모달
  const [virtualNumber, setVirtualNumber] = useState(null); //050번호별 발신 가상번호
  const [commonListType, setCommonListType] = useState(listType?.toUpperCase()); //블랙,화이트(전체 , 050 번호별 값이 같아야함)
  const [addNumberList, setAddNumberList] = useState([]); //인풋창 리스트

  useEffect(() => {
    getAllBlackWhiteData();
  }, [id]);

  function getAllBlackWhiteData() {
    const formValues = {
      agentId: Number(id),
      allStatus: status,
    };

    form.setFieldsValue(objectClean(formValues));
    //전체번호
    blackWhite.getBlackWhiteListAll(id).then((res) => {
      const resData = res?.data?.data;
      setAllList(resData);
    });
    //050번호별
    blackWhite.getBlackWhiteListByNum(id).then((res) => {
      const resData = res?.data?.data;
      setListByNum(resData);
    });
  }

  //블랙 화이트 리스트 설정변경
  function changeBlackWhiteSetting(params) {
    let reqBody = {...params};
    const errorText = {
      allStatus: "전체 설정 사용여부",
      allType: "블랙/화이트",
      callingType: "블랙/화이트",
    };
    for (var propName in params) {
      if (
        /allStatus|allType|callingType/.test(propName) &&
        (!params[propName] || params[propName] === "")
      ) {
        return globalStore.errorNoti(
          `${errorText[propName]}는 필수 값 입니다.`
        );
      }
    }
    if (params?.allStatus)
      reqBody.allStatus = params?.allStatus === "on" ? true : false;
    reqBody.allType = commonListType;
    reqBody.callingType = commonListType;
    blackWhite
      .changeBlackWhite(reqBody)
      .then((res) => {
        globalStore.successNoti("변경 되었습니다.");
        getAllBlackWhiteData();
        closeDetail();
        fetchList();
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      );
  }

  const blackWhiteRadio = () => {
    return (
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        value={commonListType}
      >
        <Popconfirm
          title={
            <>
              <b>블랙/화이트 리스트 변경</b>
              <div>
                블랙&lt;-&gt; 화이트 리스트 변경 시 기존 데이터는 삭제 됩니다
              </div>
              <div>블랙과 화이트 리스트는 동시에 사용이 불가능합니다</div>
            </>
          }
          onConfirm={() => {
            setCommonListType("BLACK");
          }}
        >
          <Radio.Button value="BLACK">Black</Radio.Button>
        </Popconfirm>
        <Popconfirm
          title={
            <>
              <b>블랙/화이트 리스트 변경</b>
              <div>
                블랙&lt;-&gt; 화이트 리스트 변경 시 기존 데이터는 삭제 됩니다
              </div>
              <div>블랙과 화이트 리스트는 동시에 사용이 불가능합니다</div>
            </>
          }
          onConfirm={() => {
            setCommonListType("WHITE");
          }}
        >
          <Radio.Button value="WHITE">White</Radio.Button>
        </Popconfirm>
      </Radio.Group>
    );
  };

  return (
    <DetailContainer viewSiderProps={viewSiderProps}>
      <div>
        <Form form={form} layout="vertical" onFinish={changeBlackWhiteSetting}>
          <Row>
            {userStore?.super && (
              <Col span={24}>
                <Form.Item name="agentId" label="사업장" required>
                  <AgentSelect disabled={!userStore?.super} showSearch />
                </Form.Item>
              </Col>
            )}
            {/* <Col span={24}>
              <Form.Item label="사업장" name="agentId" required>
                <Input.Search
                  style={{ width: "100%" }}
                  enterButton={"등록"}
                  size="middle"
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <span style={{color: "#FF0000", verticalAlign: "text-bottom"}}>
                *
              </span>
              &nbsp;
              <span>전체 설정</span>&nbsp;
              <span style={{color: "#FF0000", fontSize: "10px"}}>
                (전체 가상번호에 대한 설정입니다. ‘050 번호별’ 설정은
                유효합니다.)
              </span>
              <Divider style={{marginTop: "8px", marginBottom: "15px"}} />
            </Col>
            <Col span={12}>
              <Form.Item label="BlackWhite" required>
                {blackWhiteRadio()}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="사용 여부" name="allStatus" required>
                <Radio.Group
                  options={switchOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    발신 번호
                    <span
                      style={{
                        color: "#FF0000",
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      100개 설정
                    </span>
                  </>
                }
                required
              >
                <BlackWhiteDetailAllTable
                  agentId={id}
                  allList={allList}
                  setAllList={setAllList}
                  setInputModal={setInputModal}
                  setAddNumberList={setAddNumberList}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    050 번호별 설정
                    <span
                      style={{
                        color: "#FF0000",
                        fontSize: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      (‘전체 설정’과는 별도로 작동합니다. 전체 설정이 미사용 일
                      경우에도 작동합니다.)
                    </span>
                  </>
                }
                required
              >
                <span style={{marginRight: "27px"}}>
                  <span style={{color: "#FF0000", verticalAlign: "middle"}}>
                    *
                  </span>{" "}
                  BlackWhite
                </span>
                <Form.Item noStyle>{blackWhiteRadio()}</Form.Item>
              </Form.Item>
            </Col>
            <Col span={24}>
              <BlackWhiteDetailNumberTable
                agentId={id}
                listByNum={listByNum}
                setListByNum={setListByNum}
                setInputModal={setInputModal}
                setVirtualNumber={setVirtualNumber}
                setAddNumberList={setAddNumberList}
              />
            </Col>
          </Row>
        </Form>
      </div>
      {/* 발신번호(전체, 050) 등록 모달 */}
      {inputModal && (
        <BlackNumberInputModal
          agentId={id}
          inputModal={inputModal} // all = 전체 , number = 050, add-number = 050 가상번호 입력 후 등록, null = 닫기
          setInputModal={setInputModal}
          getAllBlackWhiteData={getAllBlackWhiteData} //상세페이지 갱신
          fetchList={fetchList} //리스트 조회 페이지 갱신
          virtualNumber={virtualNumber} // 가상번호 클릭 후 들어갈 경우
          listType={commonListType} //블랙, 화이트 타입
          addNumberList={addNumberList} //테이블 dataSource
          setAddNumberList={setAddNumberList}
        />
      )}
    </DetailContainer>
  );
}
