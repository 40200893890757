import SearchForm from "@app/components/form/SearchForm";
import {Form, Radio} from "antd";
import {useMobxStores} from "@app/stores/stores";
import {observer} from "mobx-react";
import AgentSelect from "@app/components/select/AgentSelect";

export default observer(function BlackWhiteListSearch(props) {
  const {onSearch} = props;
  const {userStore} = useMobxStores();
  const [form] = Form.useForm();

  const initValues = {
    agentId: "",
    keywordType: "",
    keyword: "",
    blackWhite: "all",
  };

  return (
    <SearchForm
      onFinish={() => onSearch(form?.getFieldsValue())}
      footerButtons={true}
    >
      <Form form={form} initialValues={initValues}>
        {userStore?.super && (
          <>
            <Form.Item label="사업장" name="agentId">
              <AgentSelect
                showSearch
                allSearch
                style={{width: "280px"}}
                params={{blackAndWhiteYN: true}}
              />
            </Form.Item>
            <Form.Item label="블랙/화이트" name="blackWhite">
              <Radio.Group
                options={[
                  {label: "전체", value: "all"},
                  {label: "블랙", value: "BLACK"},
                  {label: "화이트", value: "WHITE"},
                ]}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </SearchForm>
  );
});
