/** @jsxImportSource @emotion/react */
import {jsx, css} from "@emotion/react";
import agent from "@app/axios/agent";
import manage from "@app/axios/manage";
import DetailContainer from "@app/components/container/DetailContainer";
import PhoneNumberInput from "@app/components/input/PhoneNumberInput";
import {useMobxStores} from "@app/stores/stores";
import {Checkbox, Col, Form, Input, Row} from "antd";
import {useState} from "react";
import {useEffect} from "react";
import {observer} from "mobx-react-lite";
import sktl from "@app/axios/sktl";
import AgentSelect from "@app/components/select/AgentSelect";

export default observer(function SKTLNumberDetail(props) {
  const {fetchList} = props;
  // isAlarm : alarm 조회 유무
  const {globalStore, userStore} = useMobxStores();
  const {id, closeDetail} = props;
  const [form] = Form.useForm();
  const [detail, setDetail] = useState({});
  const [viewSiderProps, setViewSiderProps] = useState({
    title: id ? "가상번호 수정" : "가상번호 등록",
    okTitle: id ? "수정" : "등록",
    bottomActionBar: true,
    onOk: () => {
      form.submit();
    },
    onClose: () => closeDetail(),
  });

  useEffect(() => {
    if (!id) return;
    getSKTLNumberDetail();
  }, [id]);

  function getSKTLNumberDetail() {
    sktl.getSKTLNumberDetail(id).then((res) => {
      const resData = res.data.data;
      setDetail(resData);

      const formValues = {
        agentId: resData?.agent?.id, //고객사
        virtualNumber: resData?.virtualNumber, //가상번호
        realNumber: resData?.realNumber, //착신번호
        colorRing: resData?.colorRing, //컬러링
        informRing: resData?.informRing, //알림멘트
        memo: resData?.memo, //메모
        memo2: resData?.memo2, //메모2
        isDelete: resData?.isDelete,
      };
      form.setFieldsValue(formValues);
    });
  }

  function onFinish() {
    const values = form.getFieldValue();
    let params = {...values};
    if (params?.virtualNumber)
      params.virtualNumber = params?.virtualNumber.replace(/-/gi, "");
    params.colorRingId = params?.colorRing.id;
    params.informRingId = params?.informRing.id;
    if (params?.realNumber)
      params.realNumber = params?.realNumber.replace(/-/gi, "");
    delete params.colorRing;
    delete params.informRing;
    sktl
      .putSKTLNumber(params)
      .then((res) => {
        globalStore.successNoti("수정 성공");
        closeDetail();
        fetchList();
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      );
  }

  return (
    <DetailContainer viewSiderProps={viewSiderProps}>
      <div css={styles.container}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{marginBottom: "62px"}}
        >
          <Row gutter={[16, {xs: 16, sm: 24}]}>
            {userStore?.super && (
              <Col md={12} xs={24}>
                <Form.Item label="사업장" name="agentId">
                  <AgentSelect showSearch />
                </Form.Item>
              </Col>
            )}
            <Col md={12} xs={24}>
              <Form.Item name="virtualNumber" label="050번호" required>
                <PhoneNumberInput
                  disabled
                  // style={{ width: "100%" }}
                  isVirtualNum={true}
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="realNumber" label="착신번호" required>
                <PhoneNumberInput />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="컬러링" name={["colorRing", "text"]} required>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="알림 멘트"
                name={["colorRing", "text"]}
                required
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="메모" name="memo" required>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="메모2" name="memo2" required>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <span className="custom_label">
                <span>*</span> 해지
              </span>
              <Form.Item
                required
                name="isDelete"
                noStyle
                valuePropName="checked"
              >
                <Checkbox disabled={!detail?.realNumber} />
              </Form.Item>
              <span
                style={{
                  color: "#FF0000",
                  fontSize: "10px",
                  marginLeft: "14px",
                }}
              >
                해지를 하게되면 착신번호만 삭제가 됩니다.
              </span>
            </Col>
          </Row>
        </Form>
      </div>
    </DetailContainer>
  );
});

const styles = {
  container: {
    width: "100%",
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 90,
        marginRight: 7,
      },
    },
    "& .custom_label": {
      marginRight: "11px",
      "& span": {color: "red !important", marginRight: "3px"},
    },
  },
  noOperContainer: {
    "& .ant-space-horizontal": {
      marginBottom: "28px",
    },
  },
};
