/** @jsxImportSource @emotion/react */
import {jsx, css} from "@emotion/react";
import agent from "@app/axios/agent";
import manage from "@app/axios/manage";
import DetailContainer from "@app/components/container/DetailContainer";
import PhoneNumberInput from "@app/components/input/PhoneNumberInput";
import {useMobxStores} from "@app/stores/stores";
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd";
import {useState} from "react";
import {useEffect} from "react";
import VnMappWeekendTable from "@app/containers/management/vitual/detail/VnMappWeekendTable";
import dayjs from "dayjs";
import {setTimePickerFormat} from "@app/utils/format";
import {observer} from "mobx-react-lite";
import AgentSelect from "@app/components/select/AgentSelect";
import {checkAccessKeyNoPath, removeEmpty} from "@app/utils/router";
import InformRingsSelect from "@app/components/select/InformRingsSelect";
import ExternalColorRingScheduleTable from "@app/containers/management/vitual/detail/ExternalColorRingScheduleTable";
import DatePicker from "@app/components/picker/DatePicker";
import {MediaQuery} from "@utils/style";

export default observer(function VirtualMappingManageDetail(props) {
  // isAlarm : alarm 조회 유무
  const {globalStore, userStore} = useMobxStores();
  const {id, closeDetail, fetchList} = props;
  const [form] = Form.useForm();
  const [externalForm] = Form.useForm();
  //사용여부
  const switchOptions = [
    {label: "사용", value: "Y"},
    {label: "미사용", value: "N"},
  ];

  //녹취여부
  const recordOptions = [
    {label: "녹취", value: "Y"},
    {label: "미녹취", value: "N"},
  ];
  const [detail, setDetail] = useState({});
  const [externalFlexColorRings, setExternalFlexColorRings] = useState([]); //주일 컬러링
  const [expiredDate, CheckExpiredDate] = useState(false); // 기간 해지 설정 on off
  const [externalDate, CheckExternalDate] = useState(false); // 미 운영일 on off
  const [externalTime, CheckExternalTime] = useState(false); // 미 운영 시간 on off
  const [colorings, setColorings] = useState([]);
  const [viewSiderProps, setViewSiderProps] = useState({
    title: id ? "가상번호 수정" : "가상번호 등록",
    okTitle: id ? "수정" : "등록",
    bottomActionBar: true,
    onOk: () => {
      form.submit();
    },
    onClose: () => closeDetail(),
  });
  const [agentId, setAgentId] = useState(null);
  const initValues = {
    agentId: "", //고객사
    virtualNumber: "", //가상번호
    switchYn: "", //사용여부
    realNumber: "", //착신번호
    colorRingId: "", //컬러링
    informRingId: "", //알림멘트
    memo: "", //메모
    memo2: "", //메모2
    recordYN: "", //녹취여부
    //자동해지설정
    expireDateTime: null,
  };

  useEffect(() => {
    if (!id) return;
    getVirtualNumber();
  }, [id]);

  useEffect(() => {
    if (
      (userStore?.super && agentId) ||
      (userStore?.user && userStore?.super === false)
    )
      getRings();
  }, [agentId, userStore.user]);

  function getVirtualNumber() {
    manage.getVirtualNumber(id).then((res) => {
      const resData = res.data.data;
      setDetail(resData);
      const externalRange = [];
      if (resData?.externalColorRing?.colosedStartTime)
        externalRange.push(
          setTimePickerFormat(resData?.externalColorRing?.colosedStartTime)
        );
      if (resData?.externalColorRing?.closedEndTime)
        externalRange.push(
          setTimePickerFormat(resData?.externalColorRing?.closedEndTime)
        );
      setAgentId(resData?.agent?.id);
      const formValues = {
        agentId: resData?.agent?.id, //고객사
        virtualNumber: resData?.virtualNumber, //가상번호
        switchYn: resData?.switchYn ? "Y" : "N", //사용여부
        realNumber: resData?.realNumber, //착신번호
        colorRingId: resData?.colorRing?.ringId, //컬러링
        informRingId: resData?.informRing?.ringId, //알림멘트
        memo: resData?.memo, //메모
        memo2: resData?.memo2, //메모2
        recordYN: resData?.record ? "Y" : "N", //녹취여부
        expireDateTime: resData?.expireDateTime //자동해지설정
          ? dayjs(resData?.expireDateTime)
          : null,
      };
      const externalFormValues = {
        nowDateTime: resData?.externalColorRing?.nowDateTime
          ? dayjs(resData?.externalColorRing?.nowDateTime)
          : null,
        externalColorRingRange: externalRange,
        dayOffRingId: resData?.externalColorRing?.dayOffRing?.ringId,
        closedRingId: resData?.externalColorRing?.closedRing?.ringId,
      };
      setExternalFlexColorRings(
        resData?.externalFlexColorRings.map((ring, index) => ({
          key: index,
          colorRingId: ring?.colorRing?.ringId,
          startTime: ring?.startTime,
          endTime: ring?.endTime,
          week: ring?.week,
        }))
      );
      if (resData?.expireDateTime) CheckExpiredDate(true);
      if (resData?.externalColorRing?.nowDateTime) CheckExternalDate(true);
      if (
        resData?.externalColorRing?.closedEndTime ||
        resData?.externalColorRing?.colosedStartTime
      )
        CheckExternalTime(true);
      form.setFieldsValue(formValues);
      externalForm.setFieldsValue(externalFormValues);
    });
  }

  function onFinish() {
    const values = form.getFieldsValue();
    const external = externalForm.getFieldsValue();
    let params = {...values};
    params.virtualNumber = params?.virtualNumber.replace(/-/gi, "");
    if (params?.realNumber)
      params.realNumber = params?.realNumber.replace(/-/gi, "");
    params.switchYn = params.switchYn === "Y" ? true : false;
    params.isRecord = params.recordYN === "Y" ? true : false;
    params.expireDateTime = values?.expireDateTime
      ? dayjs(values?.expireDateTime).format("YYYY-MM-DD hh:mm:ss")
      : null;
    if (checkAccessKeyNoPath("EXTERNALCOLORRING", userStore)) {
      params.externalColorRing = {
        nowDateTime: external?.nowDateTime
          ? dayjs(external?.nowDateTime).format("YYYYMMDD")
          : null,
        colosedStartTime: external?.externalColorRingRange[0]
          ? dayjs(external?.externalColorRingRange[0]).format("HHmm")
          : null,
        closedEndTime: external?.externalColorRingRange[1]
          ? dayjs(external?.externalColorRingRange[1]).format("HHmm")
          : null,
        closedRingId: external?.closedRingId,
        dayOffRingId: external?.dayOffRingId,
      };
      params.externalFlexColorRings = externalFlexColorRings.map((ring) => {
        delete ring.key;
        return ring;
      });
    }
    params.externalColorRingSchedule = detail?.externalColorRingSchedule;
    delete params.recordYN;
    delete params.externalColorRingRange;
    delete params.closedEndTime;
    delete params.closedRingId;
    delete params.colosedStartTime;
    delete params.dayOffRingId;
    delete params.nowDateTime;
    manage
      .putVirtualNumber(removeEmpty(params))
      .then((res) => {
        globalStore.successNoti("수정 성공");
        fetchList();
        closeDetail();
      })
      .catch((e) => globalStore.errorNoti("수정에 실패했습니다."));
  }

  async function getRings() {
    let options = [];
    try {
      if (userStore?.super && agentId) {
        const prom = await manage.getColoringListByAgent(agentId);
        const result = prom?.data?.data.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }));
        options = result;
      } else if (userStore?.user) {
        const prom = await manage.getColoringsAllList();
        const result = prom?.data?.data.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }));
        options = result;
      }
      setColorings(options);
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      );
    }
  }

  return (
    <DetailContainer viewSiderProps={viewSiderProps}>
      <div css={styles.container}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{marginBottom: "62px"}}
          initialValues={initValues}
        >
          <Row gutter={[16, {xs: 16, sm: 24}]}>
            {userStore?.super && (
              <Col md={12} xs={24}>
                <Form.Item label="사업장" name="agentId">
                  <AgentSelect showSearch />
                </Form.Item>
              </Col>
            )}
            <Col md={12} xs={24}>
              <Form.Item name="virtualNumber" label="050번호" required>
                <PhoneNumberInput isVirtualNum={true} disabled />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="switchYn" label="사용 여부" required>
                <Radio.Group
                  options={switchOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="realNumber" label="착신번호">
                <PhoneNumberInput />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="컬러링" name="colorRingId" required>
                <Select options={colorings} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="알림 멘트" name="informRingId" required>
                <InformRingsSelect agentId={agentId} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="메모" name="memo">
                <Input />
              </Form.Item>
            </Col>
            {checkAccessKeyNoPath("MEMO2", userStore) && (
              <Col span={24}>
                <Form.Item label="메모2" name="memo2">
                  <Input />
                </Form.Item>
              </Col>
            )}
            {checkAccessKeyNoPath("VIEWRECORD", userStore) && (
              <Col md={12} xs={24}>
                <span className="custom_label">
                  <span>*</span> 녹취 여부
                </span>
                <Form.Item required name="recordYN" noStyle>
                  <Radio.Group
                    options={recordOptions}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            )}
            {/* <Col md={12} xs={24}>
              <span className="custom_label">
                <span>*</span> 문자 여부
              </span>
              <Form.Item required noStyle>
                <Radio.Group
                  options={recordOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <span className="custom_label">
                <Checkbox
                  checked={expiredDate}
                  onChange={(e) => {
                    CheckExpiredDate(e.target.checked);
                    if (e.target.checked)
                      form.setFieldsValue({
                        expireDateTime: dayjs().add(60, "minute"),
                      });
                    else form.setFieldsValue({expireDateTime: null});
                  }}
                />{" "}
                자동 해지 설정
              </span>
              <Form.Item noStyle name="expireDateTime">
                <DatePicker
                  disabled={!expiredDate}
                  format={"YYYY-MM-DD HH:mm:ss"}
                  showTime={{defaultValue: dayjs("YYYY-MM-DD HH:mm:ss")}}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* 미운영 컬러링 폼  */}
        <Form form={externalForm} css={styles.noOperContainer}>
          {checkAccessKeyNoPath("EXTERNALCOLORRING", userStore) && (
            <>
              <span className="custom_label">
                <span>*</span>
                <label style={{fontWeight: "bold"}}>미 운영 컬러링</label>
                <span style={{fontSize: "12px"}}>
                  (설정 된 일자 또는 시간대에는 호가 연결되지 않습니다.)
                </span>
              </span>
              <Divider />
              <Row>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={
                      <>
                        <Checkbox
                          checked={externalDate}
                          onChange={(e) => {
                            CheckExternalDate(e.target.checked);
                            if (!e.target.checked)
                              externalForm.setFieldsValue({
                                nowDateTime: null,
                              });
                          }}
                        />
                        &nbsp; 미 운영 일
                      </>
                    }
                    name="nowDateTime"
                    required
                  >
                    <DatePicker
                      disabled={!externalDate}
                      css={styles.mobSize}
                      style={MediaQuery[0] ? {display: "block"} : null}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={16}>
                  <Form.Item noStyle name="dayOffRingId">
                    <Select
                      options={colorings}
                      style={{minWidth: "180px"}}
                      css={styles.mobSize}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={10}>
                  <Form.Item
                    label={
                      <>
                        <Checkbox
                          checked={externalTime}
                          onChange={(e) => {
                            CheckExternalTime(e.target.checked);
                            if (!e?.target?.checked) {
                              externalForm.setFieldsValue({
                                externalColorRingRange: null,
                              });
                            }
                          }}
                        />
                        &nbsp; 미 운영 시간
                      </>
                    }
                    name="externalColorRingRange"
                    required
                  >
                    <TimePicker.RangePicker
                      format={"HH:mm"}
                      order={false}
                      disabled={!externalTime}
                      css={styles.mobSize}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={14}>
                  <Form.Item noStyle name="closedRingId">
                    <Select
                      style={{minWidth: "200px"}}
                      options={colorings}
                      css={styles.mobSize}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {detail?.externalColorRingSchedule &&
                detail?.externalColorRingSchedule[0] && (
                  <ExternalColorRingScheduleTable
                    items={detail?.externalColorRingSchedule}
                  />
                )}
              {/* 주말 컬러링 테이블 */}
              <VnMappWeekendTable
                items={externalFlexColorRings}
                setItems={setExternalFlexColorRings}
                detail={detail}
                colorings={colorings}
              />
            </>
          )}
        </Form>
      </div>
    </DetailContainer>
  );
});

const styles = {
  container: {
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 90,
        marginRight: 7,
      },
    },
    "& .custom_label": {
      marginRight: "11px",
      "& span": {color: "red !important", marginRight: "3px"},
    },
  },
  noOperContainer: {
    "& .ant-space-horizontal": {
      marginBottom: "20px",
      [MediaQuery[0]]: {
        marginBottom: 0,
      },
    },
    "& .ant-space": {
      [MediaQuery[0]]: {
        width: "100%",
      },
    },
    "& .ant-space-item": {
      [MediaQuery[0]]: {
        width: "100%",
      },
    },
  },
  mobSize: {
    [MediaQuery[0]]: {
      marginBottom: "10px",
      width: "100%",
    },
  },
};
