import SearchForm from "@app/components/form/SearchForm";
import DatePicker from "@app/components/picker/DatePicker";
import {Button, Form, Input, Radio, Select, Space} from "antd";
import {useMobxStores} from "@app/stores/stores";
import {observer} from "mobx-react";
import {checkAccessKeyNoPath} from "@app/utils/router";
import AgentSelect from "@app/components/select/AgentSelect";
import AddButton from "@app/components/button/AddButton";

export default observer(function VirtualMappingSearch(props) {
  const {onSearch, openViewSider, query, closeDetail} = props;
  const {userStore} = useMobxStores();
  const [form] = Form.useForm();
  //음원
  const recordOptions = [
    {label: "전체", value: ""},
    {label: "녹취", value: true},
    {label: "미녹취", value: false},
  ];
  //종류
  const switchOptions = [
    {label: "전체", value: ""},
    {label: "사용", value: true},
    {label: "미사용", value: false},
  ];
  //키워드
  const keywordOptions = [
    {label: "전체", value: ""},
    {label: "가상번호", value: "virtualNumber"},
    {label: "착신번호", value: "realNumber"},
    {label: "메모", value: "memo"},
    {label: "메모2", value: "memo2"},
  ];
  const initValues = {
    dateRange: [null, null],
    isRecord: "",
    switchYn: "",
    agentId: "",
    keywordType: "",
    keyword: "",
    corporation: "",
  };

  return (
    <SearchForm
      footerButtons={
        userStore?.super ? (
          <AddButton
            onClick={() => {
              openViewSider("range");
            }}
            text="대량 가상번호 관리"
          />
        ) : (
          true
        )
      }
      onFinish={() => onSearch(form.getFieldsValue())}
    >
      <Form form={form} initialValues={initValues}>
        <Form.Item name="dateRange" label="조회 날짜">
          <DatePicker.RangePicker />
        </Form.Item>
        {userStore?.super && (
          <Form.Item label="사업장" name="agentId">
            <AgentSelect showSearch style={{width: "280px"}} allSearch />
          </Form.Item>
        )}
        {checkAccessKeyNoPath("VIEWRECORD", userStore) && (
          <Form.Item name="isRecord" label="녹취 여부">
            <Radio.Group options={recordOptions} />
          </Form.Item>
        )}
        <Form.Item name="switchYn" label="사용 여부">
          <Radio.Group options={switchOptions} />
        </Form.Item>
        <Space style={{marginBottom: "16px"}}>
          <Form.Item name="keywordType" noStyle>
            <Select options={keywordOptions} style={{width: "122px"}} />
          </Form.Item>
          <Form.Item name="keyword" noStyle>
            <Input />
          </Form.Item>
        </Space>
      </Form>
    </SearchForm>
  );
});
