import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Form, Input, Select, Space} from "antd";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import {observer} from "mobx-react";

import SearchForm from "@components/form/SearchForm";
import DatePicker from "@components/picker/DatePicker";

import AgentSelect from "@app/components/select/AgentSelect";

import {Breakpoints} from "@utils/style";
import {useMobxStores} from "@stores/stores";
import {checkAccessKey} from "@utils/router";
import {useMediaQuery} from "react-responsive";

const initValue = {
  fromDate: dayjs().subtract(1, "day").format("YYYYMMDD"),
  toDate: dayjs().format("YYYYMMDD"),
  dateRange: [dayjs().subtract(1, "day"), dayjs()],
  agentId: "all",
  keywordType: "vn",
  keyword: null,
  duration: "all",
  recordYN: "all",
  sort: "releaseDateTime,desc",
};

export default observer(function CalllogSearch(props) {
  const {search} = props;
  const [form] = Form.useForm();
  const {userStore} = useMobxStores();
  const location = useLocation();
  const isMobile = useMediaQuery({maxWidth: Breakpoints[1]});

  function onSearch() {
    let params = form?.getFieldValue();
    search(params);
  }

  useEffect(() => {
    setTimeout(() => {
      onSearch();
    }, 1000);
  }, []);

  const typeOption = [
    {label: "가상번호", value: "vn", view: true},
    {label: "착신번호", value: "rn", view: true},
    {label: "발신번호", value: "an", view: true},
    {label: "메모", value: "memo", view: true},
    {
      label: "메모2",
      value: "memo2",
      view: checkAccessKey("MEMO2", userStore, location),
    },
  ];

  return (
    <>
      <SearchForm onFinish={onSearch} footerButtons={true}>
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                });
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
            />
          </Form.Item>
          <Space size={8}>
            {userStore?.super && (
              <Form.Item name="agentId" label="사업장">
                <AgentSelect allSearch style={{width: isMobile ? 120 : 200}} />
              </Form.Item>
            )}
            <Form.Item name="duration" label="통화시간">
              <Select
                options={[
                  {label: "전체", value: "all"},
                  {label: "0 초", value: "0,0"},
                  {label: "1 ~ 3 초", value: "1,3"},
                  {label: "4 ~ 5 초", value: "4,5"},
                  {label: "6 ~ 7 초", value: "6,7"},
                  {label: "8 ~ 10 초", value: "8,10"},
                  {label: "11 초 ~", value: "11"},
                  {label: "60 초 ~", value: "60"},
                  {label: "120 초 ~", value: "120"},
                ]}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
            {userStore?.super && (
              <Form.Item name="recordYN" label="녹취여부">
                <Select
                  options={[
                    {label: "전체", value: "all"},
                    {label: "녹취", value: true},
                    {label: "미녹취", value: false},
                  ]}
                  style={{
                    width: 100,
                  }}
                />
              </Form.Item>
            )}
          </Space>
          <Form.Item className="mb-0">
            <Space>
              <Form.Item name="keywordType">
                <Select
                  options={typeOption.filter((type) => {
                    return type.view === true;
                  })}
                />
              </Form.Item>
              <Form.Item name="keyword">
                <Input />
              </Form.Item>
            </Space>
          </Form.Item>
        </Form>
      </SearchForm>
    </>
  );
});
