import manage from "@app/axios/manage";
import DetailContainer from "@app/components/container/DetailContainer";
import AgentSelect from "@app/components/select/AgentSelect";
import {useMobxStores} from "@app/stores/stores";
import {checkAccessKeyNoPath} from "@app/utils/router";
import {Button, Col, Form, Input, Radio, Row, Select, Upload} from "antd";
import {observer} from "mobx-react-lite";
import {useState} from "react";
import {useEffect} from "react";

export default observer(function ColoringmanageDetail(props) {
  const {isAlarm, fetchList} = props;
  // isAlarm : alarm 조회 유무
  const {globalStore, userStore} = useMobxStores();
  const {id, closeDetail} = props;
  const [form] = Form.useForm();
  //음원
  const typeOptions = [
    {label: "WAV", value: "1"},
    {label: "TTS", value: "2"},
  ];
  //종류
  const fgOptions = [
    {label: "기본", value: "1"},
    {label: "휴무", value: "2"},
    {label: "영업종료", value: "3"},
  ];
  //초기값 설젖ㅇ
  const initValues = {
    type: "1",
    description: "",
    fg: "1",
  };
  const [filePath, setFilePath] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [viewSiderProps, setViewSiderProps] = useState({
    title: setTitle(),
    okTitle: id ? "수정" : "등록",
    bottomActionBar: true,
    onOk: () => {
      form.submit();
    },
    onClose: () => closeDetail(),
  });
  const [audioType, setAudioType] = useState("1");

  function setTitle() {
    if (isAlarm) return id ? "알림멘트 수정" : "알림멘트 등록";
    else return id ? "컬러링 수정" : "컬러링 등록";
  }

  useEffect(() => {
    if (!id) return;
    if (isAlarm) {
      manage.getAlarmDetail(id).then((res) => {
        const resData = res.data.data;
        setFilePath(resData.realPath);
        setAudioType(resData?.type);
        form.setFieldsValue({
          type: resData?.type,
          description: resData?.description,
          fg: resData?.fg,
          agentId: resData.agent?.id,
        });
      });
    } else {
      manage.getColoringDetail(id).then((res) => {
        const resData = res.data.data;
        setFilePath(resData.realPath);
        setAudioType(resData?.type);
        form.setFieldsValue({
          type: resData?.type,
          description: resData?.description,
          fg: resData?.fg,
          agentId: resData.agent?.id,
        });
      });
    }
  }, [id]);

  function fileUpload(e) {
    setFileData(e.file);
    setFilePath(e.file.name);
  }

  function onFinish(values) {
    const formData = new FormData();
    let params = {...values};
    params.type = audioType;
    if (!audioType) return globalStore.errorNoti("음원 형식을 선택해주세요.");
    if (audioType === "1" && !fileData)
      return globalStore.errorNoti(
        "음원형식이 wav인 경우, 파일은 필수 값입니다."
      );
    if (!params?.description)
      return globalStore.errorNoti("설명란을 입력해주세요.");
    if (!params?.fg) return globalStore.errorNoti("종류를 선택해주세요.");
    if (fileData) {
      formData.append("file", fileData.originFileObj);
      params.file = formData;
    }
    if (id) {
      params.ringId = Number(id);
      //수정
      if (isAlarm) {
        manage
          .putAlarm(params, fileData ? formData : null)
          .then((res) => {
            globalStore.successNoti("수정 하였습니다.");
            fetchList();
            closeDetail();
          })
          .catch((e) =>
            globalStore.errorNoti(
              e?.response?.data?.meta?.userMessage || e?.message || e
            )
          );
      } else {
        manage
          .putColoring(params, fileData ? formData : null)
          .then((res) => {
            globalStore.successNoti("수정 하였습니다.");
            fetchList();
            closeDetail();
          })
          .catch((e) =>
            globalStore.errorNoti(
              e?.response?.data?.meta?.userMessage || e?.message || e
            )
          );
      }
    } else {
      //등록
      if (isAlarm) {
        manage
          .postAlarm(params, fileData ? formData : null)
          .then((res) => {
            globalStore.successNoti("등록 하였습니다.");
            closeDetail();
            fetchList();
          })
          .catch((e) => {
            globalStore.errorNoti(
              e?.response?.data?.meta?.userMessage || e?.message || e
            );
          });
      } else {
        manage
          .postColoring(params, fileData ? formData : null)
          .then((res) => {
            globalStore.successNoti("등록 하였습니다.");
            fetchList();
            closeDetail();
          })
          .catch((e) =>
            globalStore.errorNoti(
              e?.response?.data?.meta?.userMessage || e?.message || e
            )
          );
      }
    }
  }

  return (
    <DetailContainer viewSiderProps={viewSiderProps}>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initValues}
        >
          <Row>
            {userStore?.super && (
              <Col span={24}>
                <Form.Item name="agentId" label="사업장" required>
                  <AgentSelect showSearch />
                </Form.Item>
              </Col>
            )}
            {checkAccessKeyNoPath("USINGTTS", userStore) && (
              <Col span={24}>
                <Form.Item name="type" label="음원 형식" required>
                  <Radio.Group
                    options={typeOptions}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => {
                      setAudioType(e?.target?.value);
                      if (e?.target?.value == "2") {
                        setFileData(null);
                        setFilePath(null);
                        if (!!!id) {
                          form.setFieldsValue({
                            ...form.getFieldValue(),
                            description:
                              '<vtml_speed value="100"><vtml_pause time="900"/>TTS 변환 텍스트</vtml_speed>',
                          });
                        }
                      } else {
                        if (!!!id) {
                          form.setFieldsValue({
                            ...form.getFieldValue(),
                            description: "",
                          });
                        }
                      }
                    }}
                    value={audioType}
                  />
                </Form.Item>
              </Col>
            )}
            {audioType == "1" && (
              <Col span={24}>
                <Form.Item label="파일" required style={{marginBottom: "3px"}}>
                  <Upload
                    // beforeUpload={() => false}
                    accept=".mp3, .wav"
                    onChange={fileUpload}
                    itemRender={() => null}
                    multiple={false}
                  >
                    <Button>파일 찾기</Button>
                    <span
                      style={{
                        marginLeft: "15px",
                        color: "#7C7C7C",
                        wordBreak: "break-word",
                      }}
                    >
                      {filePath}
                    </span>
                  </Upload>
                </Form.Item>
                <p style={{color: "red", fontSize: "10px"}}>
                  파일은 모든 음원 포맷을 지원하며, 영상 파일에서 음원만도
                  추출을 합니다. 파일 사이즈는 10mb 이하로 제한되며, 파일명은
                  영문만 지원합니다.
                </p>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="description"
                label={
                  <div>
                    {audioType == "2" ? "문구" : "설명"}
                    {audioType == "2" && (
                      <span
                        style={{
                          marginLeft: 10,
                          paddingTop: 13,
                          color: "red",
                          fontSize: "11px",
                        }}
                      >
                        {
                          '예시) <vtml_speed value="100"><vtml_pause time="900"/>TTS 변환 텍스트</vtml_speed>'
                        }
                      </span>
                    )}
                  </div>
                }
                required
              >
                <Input.TextArea rows={5} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="fg" label="종류">
                <Select options={fgOptions} style={{width: "122px"}} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </DetailContainer>
  );
});
